// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-announcement-js": () => import("./../../../src/components/templates/announcement.js" /* webpackChunkName: "component---src-components-templates-announcement-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcements-index-js": () => import("./../../../src/pages/announcements/index.js" /* webpackChunkName: "component---src-pages-announcements-index-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-research-domains-cancer-survivorship-js": () => import("./../../../src/pages/research-domains/cancer-survivorship.js" /* webpackChunkName: "component---src-pages-research-domains-cancer-survivorship-js" */),
  "component---src-pages-research-domains-non-cancerous-diseases-js": () => import("./../../../src/pages/research-domains/non-cancerous-diseases.js" /* webpackChunkName: "component---src-pages-research-domains-non-cancerous-diseases-js" */),
  "component---src-pages-research-domains-pediatric-cancer-js": () => import("./../../../src/pages/research-domains/pediatric-cancer.js" /* webpackChunkName: "component---src-pages-research-domains-pediatric-cancer-js" */),
  "component---src-pages-studies-clinical-genomics-js": () => import("./../../../src/pages/studies/clinical-genomics.js" /* webpackChunkName: "component---src-pages-studies-clinical-genomics-js" */),
  "component---src-pages-studies-index-js": () => import("./../../../src/pages/studies/index.js" /* webpackChunkName: "component---src-pages-studies-index-js" */),
  "component---src-pages-studies-netad-js": () => import("./../../../src/pages/studies/netad.js" /* webpackChunkName: "component---src-pages-studies-netad-js" */),
  "component---src-pages-studies-pediatric-cancer-genome-project-js": () => import("./../../../src/pages/studies/pediatric-cancer-genome-project.js" /* webpackChunkName: "component---src-pages-studies-pediatric-cancer-genome-project-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

