import AppsOverview from "../../../../src/content/announcements/st-jude-cloud-restructure/figure_sjc-apps.png";
import NewStructure from "../../../../src/content/announcements/st-jude-cloud-restructure/figure_stjudecloud_structure.png";
import GenomicsPlatformHome from "../../../../src/content/announcements/st-jude-cloud-restructure/figure_platform-home.png";
import PeCanHome from "../../../../src/content/announcements/st-jude-cloud-restructure/figure_pecan-home.png";
import VisualizationCommunityHome from "../../../../src/content/announcements/st-jude-cloud-restructure/figure_vizcomm-home.png";
import * as React from 'react';
export default {
  AppsOverview,
  NewStructure,
  GenomicsPlatformHome,
  PeCanHome,
  VisualizationCommunityHome,
  React
};