module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"sizeByPixelDensity":false,"quality":100}},{"resolve":"gatsby-remark-external-links","options":{"target":null,"rel":"nofollow noopener noreferrer external"}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"St. Jude Cloud","short_name":"St. Jude Cloud","start_url":"/","background_color":"#ffffff","theme_color":"#1874dc","display":"standalone","icon":"src/images/logo-icon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"485b1a9a199adf4a793e51014ba4576d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-107924368-1","anonymize":true,"respectDNT":true,"head":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"zxn8gz0o","include_in_development":true,"delay_timeout":500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
